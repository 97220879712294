<template>
    <div class="d-flex flex-column align-center">
        <product-banner>Add More Campaigns</product-banner>
        <product-card id="356">
            <template #title>1 Amp Credit</template>
            <template #description>
                Amplify your content, products, and business across the internet
                to drive more organic traffic.
                <br />
                <strong>Create. Repurpose. Distribute.</strong>
                <br />
                Each amp credit allows you to create content and repurpose it
                rapidly into 6 different formats: article, infographic, video,
                audio ad, slideshow and blog post. Then at a click of a button
                you can distribute this content to hundreds of sites across the
                internet to get more exposure and organic traffic.
            </template>
        </product-card>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { ProductBanner, ProductCard } from '@/components/ProductCard';
@Component({
    components: {
        ProductBanner,
        ProductCard
    }
})
export default class Amp extends Vue {
    mounted() {
        this.$emit(
            'appointment',
            'https://try.ampifire.com/meetings/jbulzak/amp-strategy-meeting'
        );
    }
}
</script>
